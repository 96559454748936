@import 'assets/scss/variables/all.scss';

.app-stack {
  
  .app-stackBody {

    overflow: hidden;
  
    .app-stackMain {

      overflow-y: auto;
  
      .app-stackMainHeader {
        position: relative;
      }
    
    }

  }

}
