@import 'variables/all.scss';

.ms-Nav-link {
  color: black;
}

.ms-Button-red {
  @extend .bg-red;
  @extend .text-white;
  border: none;
}

.ms-Button-red:hover {    
  background-color: rgba($color-red, .8) !important;
  border: none !important;
  color: white !important;
}

.ms-Panel-footer {
  z-index: 1;
}

.ms-Button-green {
  @extend .bg-green;
  @extend .text-white;
  border: none;
}

.ms-Button-green:hover {    
  background-color: rgba($color-green, .8) !important;
  border: none !important;
  color: white !important;
}
