@import 'assets/scss/variables/colors';

.app-headerItemsUserPopup {
  flex-direction: column;
  min-width: 320px;
  max-width: 400px;
  display: flex;
  flex-grow: 1;
  // First column (logo TP & button sign out)
  > :nth-child(1) {
    justify-content: space-between;
    display: flex;
    // Div for img logo TP
    > :nth-child(1) {
      align-items: center;
      display: flex;
      // img logo tp
      img {
        padding: 0px 0px 0px 16px;
        height: 30px;
        width: auto;
      }
    }
    // Button 'Sign out'
    > :nth-child(2) {
      padding: 16px;
      user-select: none;
    }
  }
  // Div with button sign in with different account
  > :nth-child(3) {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: $ms-color-gray30;
    // Div with icon
    > :first-child {
      // flex
      justify-content: center;
      align-items: center;
      display: flex;
      // Sizes
      height: 40px;
      width: 40px;
      // Margin/Padding
      margin: 10px 12px 10px 16px;
      // Border
      border-style: solid;
      border-radius: 50%;
      border-width: 1px;
      border-color: $ms-color-gray80;
    }
  }

  [role='button']:hover {
    background-color: $ms-color-gray20;
  }

  .ms-Button-textContainer {
    color: $color-green;
  }
}
