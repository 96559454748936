@import "assets/scss/variables/colors";

.app-CreateUpdateConsultantExperienceFormSkillsOptionItem {
  [role="button"]:hover {
    background-color: $ms-color-gray20;
  }
  
  [role="button"]:active {
    background-color: $ms-color-gray30;
  }
}
