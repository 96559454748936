@import '../../../../node_modules/@fluentui/react/dist/sass/variables/Color.Variables';

$color-red: #a4373a;
$color-orange: #FF8C00;
$color-green: #1d6257;
$color-green-transparent: rgba(29, 98, 87, 0.1);
$color-green-alt: #133f38;

// Devops Color
$devops-product-backlog-item: rgb(0, 156, 204);
$devops-task: rgb(242, 203, 29);
$devops-bug: rgb(204, 41, 61);

$devops-state-white: rgb(86, 136, 224);//yes, it's blue
$devops-state-grey: rgb(178, 178, 178);
$devops-state-blue: rgb(0, 122, 204);
$devops-state-green: rgb(51, 153, 51);

$devops-blue: rgb(0,90,158);