.app-QuickFilter {
  
  padding: 8px;
  background-color: white;
  height: 33px;
  border: 1px solid rgb(50, 49, 48);

  input {
    background-color: transparent;
    outline: none;
    border: none;
    resize: none;
    font-size: 1rem;
  }

  [data-icon-name] {
    font-size: 0.75rem;
    padding: 8px;
  }

}
