.app-guard {

  img {
    height: 500px;
    width: 500px;
  }

  a {
    text-decoration: none;
    margin-top: 16px;
  }

  .app-guardContent {
    width: 500px;
  }

  .ms-Button-label {
    font-weight: 600;
  }

}
