@import "variables/all.scss";
@import "attributes.scss";
@import "fluentUI.scss";
@import "spacing.scss";
@import "html.scss";
@import "flex.scss";

.box-shadow {
  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
}

.rounded {
  border-radius: 0.25rem !important;
}

.w-100 {
  width: 100% !important;
}

.w-80 {
  width: 80% !important;
}

.w-75 {
  width: 75% !important;
}

.w-70 {
  width: 70% !important;
}

.w-30 {
  width: 30% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.h-100 {
  height: 100% !important;
}

.rdw-editor-toolbar {
  margin-bottom: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}

.w-auto {
  width: auto;
}

.rounded-lg {
  border-radius: 0.5rem; /* 8px */
}

.rounded-s-lg {
  border-start-start-radius: 0.5rem; /* 8px */
  border-end-start-radius: 0.5rem; /* 8px */
}

.rounded-e-lg {
  border-start-end-radius: 0.5rem; /* 8px */
  border-end-end-radius: 0.5rem; /* 8px */
}

.border-0 {
  border-width: 0px;
}

.text-center {
  text-align: center;
}

.border-red {
  border-color: #e81123;
}

.border {
  border-width: 1px;
}

.italic {
  font-style: italic;
}

.opacity-75 {
  opacity: 0.75;
}

.border-b {
  border-bottom-width: 1px;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}
