@import 'assets/scss/variables/colors';

.app-loadingScreen {
  height: 100vh;
  width: 100%;

  img {
    height: 100px;
    width: auto;
  }
}
