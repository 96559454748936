@import "assets/scss/variables/colors";

.app-menu {
  position: relative;
  background-color: $ms-color-gray40;
  width: 260px;

  .app-menu-body {
    overflow-y: auto;
    width: 260px;
  }
}

.app-menu-exit-done {
  width: 45px;
  overflow: hidden;
}

/* Animation 'enter' */

.app-menu-enter {
  width: 45px;
  overflow: hidden;
}

.app-menu-enter-active {
  width: 260px;
  transition: width 200ms ease-out;
}

/* Animation 'exit' */

.app-menu-exit {
  width: 260px;
}

.app-menu-exit-active {
  width: 45px;
  transition: width 200ms ease-out;
}

