@import 'assets/scss/variables/colors';

.app-stackHeader {
  .app-stackHeaderContent {
    min-height: 53px;
    background-color: white;
    box-shadow: 0px 1px 0px rgb(0 0 0 / 8%);

    .app-header-logo {
      width: 260px;
      img {
        height: 45px;
        width: auto;
      }

      .app-header-bourger {
        width: 45px;
      }
    }

    [role='button']:hover {
      background-color: $ms-color-gray20;
    }
  }

  .app-stackprogressIndicator {
    .ms-ProgressIndicator-itemProgress {
      padding: 0px;
    }
  }

  .app-headerItemsNotifications {
    position: relative;

    .app-headerItemsNotificationsMarker {
      position: absolute;
      font-size: x-small;
      z-index: 1;
      right: 10px;
      top: 13px;
    }
  }
}

.deleteOneNotificationButton:hover{
  color: black;
  font-weight: bold;
}

.notificationStack:hover {
  background-color: hsl(0, 0%, 95%) 
}
