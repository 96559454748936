@import 'colors';

.text-green {
  color: $color-green  !important;
}

.text-red {
  color: $color-red !important;
}

.text-yellow {
  color: #c19c00 !important;
}

.text-blue {
  color: #004e8c !important;
}

.text-white {
  color: white !important;
}

.bg-green {
  background-color: $color-green  !important;
}

.bg-white {
  background-color: white  !important;
}

.bg-blue {
  background-color: #004e8c  !important;
}

.bg-red {
  background-color: $color-red  !important;
}
