@import 'assets/scss/variables/colors';

.app-fileInput {
  .fileInput-line {
    align-items: center;

    .fileInput-label {
      font-size: 14px;
      font-weight: 400;
      box-sizing: border-box;
      border: 1px solid rgb(29, 98, 87);
      cursor: pointer;
      padding: 0px 16px;
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
      min-width: 80px;
      height: 32px;
      background-color: rgb(29, 98, 87);
      color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        padding-right: 0.5em;
      }
    }
    .fileInput-label:hover {
      background-color: rgb(19, 63, 56);
      border: 1px solid rgb(19, 63, 56);
      color: rgb(255, 255, 255);
    }

    .fileInput-span {
      flex-grow: 1;
      padding: 0 1em;
      border: 1px solid rgb(96, 94, 92);
      border-left: 0;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      min-width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
    }

    input {
      display: none;
    }
  }

  .fileInput-pasteArea {
    margin-top: 0.5em;
    padding: 1em;
    height: 60px;
    border: 1px solid rgb(19, 63, 56);

    font-style: italic;
    color: rgb(96, 94, 92);
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .fileInput-commentary {
    font-style: italic;
    font-size: 12px;
    color: $ms-color-gray120;
  }
}