@import '../../assets/scss/variables/colors';

.app-agGrid {
  width: 100%;
}

.ag-theme-alpine {
  .ag-root-wrapper {
    border: none !important;

    .ag-checkbox-input-wrapper.ag-checked::after {
      color: $color-green !important;
    }
  }

  .ag-row {
    border-left: solid;
    border-left-color: transparent;
  }

  .ag-cell {
    border: none !important;
  }

  .ag-cell-focus {
    border: none !important;
  }

  .ag-row:hover {
    // background-color: #1d6257 !important;
    // background-color: rgba(29, 98, 87, 0.1) !important;
    background-image: none;
  }

  .ag-row-selected::before {
    background-color: transparent !important;
    background-image: none;
  }

  .ag-row-selected {
    background-color: $color-green-transparent !important;
    border-left: solid;
    border-left-color: #1d6257 !important;
  }

  .ag-cell-value > .app-userInformations {
    line-height: 20px !important;
    word-break: normal;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
